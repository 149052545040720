exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cossac-js": () => import("./../../../src/pages/cossac.js" /* webpackChunkName: "component---src-pages-cossac-js" */),
  "component---src-pages-developers-js": () => import("./../../../src/pages/developers.js" /* webpackChunkName: "component---src-pages-developers-js" */),
  "component---src-pages-epc-js": () => import("./../../../src/pages/EPC.js" /* webpackChunkName: "component---src-pages-epc-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-hpc-js": () => import("./../../../src/pages/HPC.js" /* webpackChunkName: "component---src-pages-hpc-js" */),
  "component---src-pages-ignition-js": () => import("./../../../src/pages/ignition.js" /* webpackChunkName: "component---src-pages-ignition-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-scoreboard-js": () => import("./../../../src/pages/scoreboard.js" /* webpackChunkName: "component---src-pages-scoreboard-js" */),
  "component---src-pages-sponsors-jsx": () => import("./../../../src/pages/sponsors.jsx" /* webpackChunkName: "component---src-pages-sponsors-jsx" */)
}

